import React from 'react';
import {
  DISCOUNT_TYPE_BUY_X_GET_Y,
  DISCOUNT_TYPE_CART_FIXED_AMOUNT,
  DISCOUNT_TYPE_CART_PERCENTAGE,
  DISCOUNT_TYPE_FREE_ENGRAVING,
  DISCOUNT_TYPE_FREE_SHIPPING,
  DISCOUNT_TYPE_PRODUCT_FIXED_AMOUNT,
  DISCOUNT_TYPE_PRODUCT_PERCENTAGE,
  DISCOUNT_TYPE_SHIPPING_PERCENTAGE,
  TIME_ZONE
} from './constants';
import moment from 'moment-timezone';
import { DATE_FORMAT, DATETIME_FORMAT } from '../utils';

export const asFormData = (payload, nestedFields = []) => {
  const data = new FormData();
  for (const key in payload) {
    let value = payload[key];
    if (value === null) {
      value = '';
    }
    if (value instanceof Array) {
      const isNestedArray =
        value.find((nestedArrayItem) => nestedArrayItem instanceof Object) || nestedFields.includes(key);
      if (isNestedArray) {
        //  in this case, we have a value that is an object list, so we will encode as JSON and hope the backend can
        //  deal with it
        data.append(key, JSON.stringify(value));
      } else {
        for (const index in value) {
          data.append(`${key}`, value[index]);
        }
      }
    } else {
      data.append(key, value);
    }
  }
  return data;
};

const groupParamsByKey = (params) =>
  [...params.entries()].reduce((acc, tuple) => {
    const [key, val] = tuple;
    // eslint-disable-next-line no-prototype-builtins
    if (acc.hasOwnProperty(key)) {
      if (Array.isArray(acc[key])) {
        acc[key] = [...acc[key], val];
      } else {
        acc[key] = [acc[key], val];
      }
    } else {
      acc[key] = val;
    }
    return acc;
  }, {});

// this method is a hotfix to convert false as string from URL search params to javascript true/false
export const normalizeBooleanFromSearchParams = (payload) => {
  payload = groupParamsByKey(payload);
  const data = {};
  for (const field of Object.keys(payload)) {
    const value = payload[field];
    if (value === 'false' || value === 'true') {
      data[field] = JSON.parse(value);
    } else {
      data[field] = payload[field];
    }
  }
  return data;
};

export const cellAsDatetime = (rowData, field) => {
  const currentDatetime = rowData.row.original[field];
  const formattedDate = currentDatetime ? moment.parseZone(currentDatetime).format(DATETIME_FORMAT) : '-';
  return <span>{formattedDate}</span>;
};

export const formatDatetime = (datetime) => {
  if (datetime) {
    const currentDatetime = moment.parseZone(datetime);
    return currentDatetime.tz(TIME_ZONE).format(DATETIME_FORMAT);
  }
  return null;
};

export const formatDateWithMoment = (datetime) => {
  if (datetime) {
    const currentDatetime = moment.parseZone(datetime);
    return currentDatetime.format(DATE_FORMAT);
  }
  return null;
};

export const SOURCE_LIST = [
  {
    label: 'Manual',
    value: 0
  },
  {
    label: 'BuyButton',
    value: 1
  }
];
export const STATE_LIST = [
  {
    label: 'Alabama',
    value: 'AL'
  },
  {
    label: 'Alaska',
    value: 'AK'
  },
  {
    label: 'American Samoa',
    value: 'AS'
  },
  {
    label: 'Arizona',
    value: 'AZ'
  },
  {
    label: 'Arkansas',
    value: 'AR'
  },
  {
    label: 'California',
    value: 'CA'
  },
  {
    label: 'Colorado',
    value: 'CO'
  },
  {
    label: 'Connecticut',
    value: 'CT'
  },
  {
    label: 'Delaware',
    value: 'DE'
  },
  {
    label: 'District Of Columbia',
    value: 'DC'
  },
  {
    label: 'Federated States Of Micronesia',
    value: 'FM'
  },
  {
    label: 'Florida',
    value: 'FL'
  },
  {
    label: 'Georgia',
    value: 'GA'
  },
  {
    label: 'Guam',
    value: 'GU'
  },
  {
    label: 'Hawaii',
    value: 'HI'
  },
  {
    label: 'Idaho',
    value: 'ID'
  },
  {
    label: 'Illinois',
    value: 'IL'
  },
  {
    label: 'Indiana',
    value: 'IN'
  },
  {
    label: 'Iowa',
    value: 'IA'
  },
  {
    label: 'Kansas',
    value: 'KS'
  },
  {
    label: 'Kentucky',
    value: 'KY'
  },
  {
    label: 'Louisiana',
    value: 'LA'
  },
  {
    label: 'Maine',
    value: 'ME'
  },
  {
    label: 'Marshall Islands',
    value: 'MH'
  },
  {
    label: 'Maryland',
    value: 'MD'
  },
  {
    label: 'Massachusetts',
    value: 'MA'
  },
  {
    label: 'Michigan',
    value: 'MI'
  },
  {
    label: 'Minnesota',
    value: 'MN'
  },
  {
    label: 'Mississippi',
    value: 'MS'
  },
  {
    label: 'Missouri',
    value: 'MO'
  },
  {
    label: 'Montana',
    value: 'MT'
  },
  {
    label: 'Nebraska',
    value: 'NE'
  },
  {
    label: 'Nevada',
    value: 'NV'
  },
  {
    label: 'New Hampshire',
    value: 'NH'
  },
  {
    label: 'New Jersey',
    value: 'NJ'
  },
  {
    label: 'New Mexico',
    value: 'NM'
  },
  {
    label: 'New York',
    value: 'NY'
  },
  {
    label: 'North Carolina',
    value: 'NC'
  },
  {
    label: 'North Dakota',
    value: 'ND'
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP'
  },
  {
    label: 'Ohio',
    value: 'OH'
  },
  {
    label: 'Oklahoma',
    value: 'OK'
  },
  {
    label: 'Oregon',
    value: 'OR'
  },
  {
    label: 'Palau',
    value: 'PW'
  },
  {
    label: 'Pennsylvania',
    value: 'PA'
  },
  {
    label: 'Puerto Rico',
    value: 'PR'
  },
  {
    label: 'Rhode Island',
    value: 'RI'
  },
  {
    label: 'South Carolina',
    value: 'SC'
  },
  {
    label: 'South Dakota',
    value: 'SD'
  },
  {
    label: 'Tennessee',
    value: 'TN'
  },
  {
    label: 'Texas',
    value: 'TX'
  },
  {
    label: 'Utah',
    value: 'UT'
  },
  {
    label: 'Vermont',
    value: 'VT'
  },
  {
    label: 'Virgin Islands',
    value: 'VI'
  },
  {
    label: 'Virginia',
    value: 'VA'
  },
  {
    label: 'Washington',
    value: 'WA'
  },
  {
    label: 'West Virginia',
    value: 'WV'
  },
  {
    label: 'Wisconsin',
    value: 'WI'
  },
  {
    label: 'Wyoming',
    value: 'WY'
  },
  {
    label: 'Armed Forces Americas',
    value: 'AA'
  },
  {
    label: 'Armed Forces Europe',
    value: 'AE'
  },
  {
    label: 'Armed Forces Pacific',
    value: 'AP'
  }
];

export const ORDER_UPDATABLE_STATUS = ['new_order', 'action_req'];

export const ORDER_STATUS = [
  { value: 'new_order', label: 'New order' },
  {
    value: 'action_req',
    label: 'Action required'
  },
  { value: 'confirmed', label: 'Confirmed' },
  { value: 'printed', label: 'Printed' },
  {
    value: 'packed',
    label: 'Packed'
  },
  { value: 'pre_transit', label: 'Pre transit' },
  { value: 'in_transit', label: 'In transit' },
  {
    value: 'canceled',
    label: 'Canceled'
  },
  { value: 'completed', label: 'Completed' },
  { value: 'processing', label: 'Processing' }
];

export const TRACKING_COMPANIES = [
  { value: 'ups', label: 'Ups' },
  { value: 'speedee', label: 'Speedee' },
  {
    value: 'lso',
    label: 'Lso'
  },
  { value: 'vingo', label: 'Vingo' },
  { value: 'fedex', label: 'Fedex' },
  { value: 'gso', label: 'Gso' },
  {
    value: 'gio',
    label: 'Gio'
  },
  { value: 'gls', label: 'Gls' },
  { value: 'uds', label: 'Uds' },
  {
    value: 'fxtran',
    label: 'Falcon Express Transportation'
  },
  { value: 'fedexpop', label: 'fedexPOP' },
  { value: 'ep_ups', label: 'Ep Ups' },
  {
    value: 'bettertrucks',
    label: 'Better Trucks'
  },
  { value: 'reef', label: 'Reef' },
  { value: 'promed', label: 'ProMed Delivery"' }
];

export const DISCOUNT_TYPES = [
  {
    value: DISCOUNT_TYPE_PRODUCT_FIXED_AMOUNT,
    label: 'Product Fixed Amount'
  },
  { value: DISCOUNT_TYPE_PRODUCT_PERCENTAGE, label: 'Product Percentage' },
  {
    value: DISCOUNT_TYPE_CART_FIXED_AMOUNT,
    label: 'Cart Fixed Amount'
  },
  { value: DISCOUNT_TYPE_CART_PERCENTAGE, label: 'Cart Percentage' },
  {
    value: DISCOUNT_TYPE_SHIPPING_PERCENTAGE,
    label: 'Shipping Percentage'
  },
  {
    value: DISCOUNT_TYPE_FREE_SHIPPING,
    label: 'Free Shipping'
  },
  {
    value: DISCOUNT_TYPE_FREE_ENGRAVING,
    label: 'Free Engraving'
  },
  {
    value: DISCOUNT_TYPE_BUY_X_GET_Y,
    label: 'Buy X Get Y'
  }
];

export const formatPhoneNumber = (value, country = 'US') => {
  if (!value) return '';
  const phoneNumber = value.replace(/\D/gi, '');

  if (country === 'US') {
    if (phoneNumber.length <= 6) {
      return phoneNumber.replace(/(\d{3})(\d.*)/, '($1) $2');
    }
    return phoneNumber.replace(/(\d{3})(\d{3})(\d.*)/, '($1) $2-$3');
  } else if (country === 'DE') {
    return phoneNumber.replace(/(\d{2})(\d.*)/, '$1 $2');
  } else if (country === 'GB') {
    if (phoneNumber.length <= 5) {
      return phoneNumber;
    }
    return phoneNumber.replace(/(\d{4})(\d.*)/, '$1 $2');
  }

  return phoneNumber;
};

export const formatDate = (value) => {
  if (!value) return '';
  value = `${value}`;
  const formattedDate = value.replace(/\D/gi, '');
  if (formattedDate.length <= 4) {
    return formattedDate.replace(/(\d{2})(\d.*)/, '$1/$2');
  }
  return formattedDate.replace(/(\d{2})(\d{2})(\d.*)/, '$1/$2/$3').substring(0, 10);
};

export const SUPPORT_WEBSITE = 'https://bottlenexus.com/';

export const moneyFormat = (value) => {
  if (!value) {
    value = 0;
  }
  const money = value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  return `$${money}`;
};

export const RECOVERY_STATUS_RECOVERED = 'recovered';
export const RECOVERY_STATUS_NOT_RECOVERED = 'not-recovered';
export const RECOVERY_STATUS_LIST = [
  {
    label: 'Recovered',
    value: RECOVERY_STATUS_RECOVERED
  },
  {
    label: 'Not Recovered',
    value: RECOVERY_STATUS_NOT_RECOVERED
  }
];
export const SUBSCRIPTION_INCOMPLETE = 'incomplete';
export const SUBSCRIPTION_INCOMPLETE_EXPIRED = 'incomplete_expired';
export const SUBSCRIPTION_TRIALING = 'trialing';
export const SUBSCRIPTION_ACTIVE = 'active';
export const SUBSCRIPTION_PAST_DUE = 'past_due';
export const SUBSCRIPTION_CANCELED = 'canceled';
export const SUBSCRIPTION_UNPAID = 'unpaid';
export const SUBSCRIPTION_PAUSED = 'paused';

export const SUBSCRIPTION_STATUS_CHOICES = [
  { value: SUBSCRIPTION_INCOMPLETE, label: 'Incomplete' },
  { value: SUBSCRIPTION_INCOMPLETE_EXPIRED, label: 'Incomplete Expired' },
  { value: SUBSCRIPTION_TRIALING, label: 'Trialing' },
  { value: SUBSCRIPTION_ACTIVE, label: 'Active' },
  { value: SUBSCRIPTION_PAST_DUE, label: 'Past Due' },
  { value: SUBSCRIPTION_CANCELED, label: 'Canceled' },
  { value: SUBSCRIPTION_UNPAID, label: 'Unpaid' },
  { value: SUBSCRIPTION_PAUSED, label: 'Paused' }
];

export const STATUS_LIVE = 0;
export const STATUS_PENDING = 1;
export const STATUS_CANCELLED = 2;
export const STATUS_CHOICES = [
  { value: STATUS_LIVE, label: 'Live' },
  { value: STATUS_PENDING, label: 'Pending' },
  { value: STATUS_CANCELLED, label: 'Cancelled' }
];

export const USER_TYPE_BRAND = 'brand';
export const USER_TYPE_ADMIN = 'admin';

export const isImage = (url) => {
  return ['.png', '.svg', '.gif', '.jpeg', '.jpg'].map((ex) => url.endsWith(ex)).find((i) => i);
};

export const EXPORT_PENDING = 'pending';
export const EXPORT_GENERATED = 'generated';
export const EXPORT_FAILED = 'failed';
export const EXPORT_CANCELED = 'canceled';

export const EXPORT_STATUS = [
  { value: EXPORT_PENDING, label: 'Pending' },
  { value: EXPORT_GENERATED, label: 'Generated' },
  { value: EXPORT_FAILED, label: 'Failed' },
  { value: EXPORT_CANCELED, label: 'Canceled' }
];

export const IMPORT_STATUS = [
  {
    value: 'pending',
    label: 'Pending'
  },
  { value: 'generated', label: 'Generated' },
  { value: 'failed', label: 'Failed' },
  { value: 'incomplete', label: 'Incomplete' }
];

export const EXPORT_TYPES = [
  { value: 'brand', label: 'Brand' },
  { value: 'product', label: 'Product' },
  { value: 'customer', label: 'Customer' },
  { value: 'order', label: 'Order' },
  { value: 'abandoned-checkout', label: 'Abandoned Checkout' },
  { value: 'discount', label: 'Discount' },
  { value: 'gift-card', label: 'Gift Card' }
];
export const AFFILIATE_EXPORT_TYPES = [
  { value: 'product', label: 'Product' },
  { value: 'order', label: 'Order' },
  { value: 'abandoned-checkout', label: 'Abandoned Checkout' }
];
export const MARKETING_AUTOMATION_KLAVIYO = 'klaviyo';
export const MARKETING_AUTOMATION_MAILCHIMP = 'mailchimp';
export const MARKETING_AUTOMATION_ACTIVECAMPAIGN = 'activecampaign';

export const MARKETING_AUTOMATION_SERVICES = [
  { value: MARKETING_AUTOMATION_KLAVIYO, label: 'Klaviyo' },
  { value: MARKETING_AUTOMATION_MAILCHIMP, label: 'Mailchimp' },
  { value: MARKETING_AUTOMATION_ACTIVECAMPAIGN, label: 'ActiveCampaign' }
];
export const asCSVRow = (value) => `"${value}"`;
