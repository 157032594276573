import React, { useContext } from 'react';
import { ImageUploadField } from '../../../components/form/ImageField';
import TextField from '../../../components/form/TextField';
import MaskedTextField from '../../../components/form/MaskedTextField';
import { slugify } from '../../../utils';
import PhoneField from '../../../components/form/PhoneField';
import AsyncSelectField, { SimpleSelectField } from '../../../components/form/SelectField';
import { ADMIN_OR_BRAND, BRAND_PRIORITY_OPTIONS, USER_PERMISSION_BRAND_EDIT } from '../../../services/constants';
import CurrencyField from '../../../components/form/CurrencyField';
import { PositiveIntegerField } from '../../../components/form/PositiveNumberField';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import AppContext from '../../../context/Context';
import { USER_TYPE_ADMIN } from '../../../services/utils';
import PropTypes from 'prop-types';

export const InformationFragment = () => {
  const { isSubmitting } = useFormikContext();
  let { id } = useParams();
  const { profile } = useContext(AppContext);
  const isReadOnly = id && !profile?.permissions?.includes(USER_PERMISSION_BRAND_EDIT);
  const isAdmin = profile?.type === USER_TYPE_ADMIN;

  return (
    <div className="row mb-3">
      <div className="col-12 col-sm-4 col-lg-3">
        <ImageUploadField label="Image" name="photo" required={false} disabled={isSubmitting || isReadOnly} />
      </div>
      <div className="col-12 col-sm-8 col-lg-9">
        <TextField
          label="Name"
          required={true}
          name="name"
          maxLength={70}
          type="text"
          disabled={isSubmitting || isReadOnly}
        />
        <MaskedTextField
          maskInput={(value) => slugify(value).toUpperCase()}
          label="Prefix"
          required={true}
          name="prefix"
          type="text"
          disabled={isSubmitting || isReadOnly || profile.type != USER_TYPE_ADMIN}
          maxLength={10}
        />
        <div className="row">
          <div className="col-sm-6">
            <TextField
              label="Email"
              name="email"
              data-lpignore="true"
              autoComplete="new-password"
              type="email"
              disabled={isSubmitting || isReadOnly}
            />
          </div>
          <div className="col-sm-6">
            <TextField label="Site URL" name="website_url" type="text" disabled={isSubmitting || isReadOnly} />
          </div>
          <div className="col-sm-6">
            <PhoneField
              label="Phone Number"
              name="phone_number"
              data-lpignore="true"
              autoComplete="off"
              disabled={isSubmitting || isReadOnly}
              freeForm
            />
          </div>
          <div className="col-sm-6">
            <TextField
              label="Headquarter Address"
              name="headquarter_address"
              data-lpignore="true"
              autoComplete="new-password"
              disabled={isSubmitting || isReadOnly}
            />
          </div>
        </div>
        {isAdmin && (
          <>
            <div className="row">
              <div className="col-sm-6">
                <SimpleSelectField
                  label="Value"
                  options={BRAND_PRIORITY_OPTIONS}
                  disabled={isSubmitting || isReadOnly}
                  name="value"
                  style={{ width: '100%' }}
                  className="mb-0 me-3"
                />
              </div>
              <div className="col-sm-6">
                <AsyncSelectField
                  allowNull={true}
                  label="Provider"
                  name="provider"
                  required={false}
                  disabled={isSubmitting || isReadOnly}
                  urlEndpoint="/dropdown/provider/"
                  isMulti={false}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <CurrencyField
                  label="Minimum Purchase Value"
                  name="minimum_purchase_value"
                  required={false}
                  disabled={isSubmitting || isReadOnly}
                />
              </div>
              <div className="col-sm-6">
                <PositiveIntegerField
                  step={1}
                  label="Minimum Quantity"
                  name="minimum_quantity"
                  disabled={isSubmitting || isReadOnly}
                />
              </div>
            </div>
          </>
        )}

        <div className="row mb-2">
          {ADMIN_OR_BRAND.includes(profile?.type) && (
            <div className="col-sm-6">
              <AsyncSelectField
                label="Portfolios"
                name="portfolios"
                required={false}
                disabled={isSubmitting || isReadOnly || !isAdmin}
                urlEndpoint="/dropdown/portfolio/"
                isMulti={true}
              />
            </div>
          )}
          {isAdmin ? (
            <>
              <div className="col-sm-6">
                <AsyncSelectField
                  label="Allow Shipping To"
                  name="allowed_countries"
                  required={false}
                  disabled={isSubmitting || isReadOnly}
                  urlEndpoint="/dropdown/country/"
                  isMulti={true}
                />
              </div>
              <div className="col-sm-6">
                <AsyncSelectField
                  label="Account Manager"
                  name="account_manager"
                  required={false}
                  disabled={isSubmitting || isReadOnly || !isAdmin}
                  urlEndpoint="/dropdown/admin-users/"
                  isMulti={false}
                  allowNull={true}
                />
              </div>
            </>
          ) : (
            <div className="col-sm-6">
              <TextField
                label="Account Manager"
                name="account_manager_name"
                type="text"
                extraClass={'mt-1'}
                required={false}
                disabled={isSubmitting || isReadOnly || profile.type !== USER_TYPE_ADMIN}
              />
            </div>
          )}
          <div className="col-sm-6">
            <TextField
              label="Engraving Disclaimer"
              name="engraving_disclaimer"
              type="text"
              extraClass={'mt-1'}
              required={false}
              helpText="Leave blank to apply the default setting"
              disabled={isSubmitting || isReadOnly || profile.type !== USER_TYPE_ADMIN}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

InformationFragment.propTypes = {
  brand: PropTypes.any
};
