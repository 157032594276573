import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDatetime } from '../../../services/utils';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../context/Context';
import { USER_TYPE_ADMIN } from '../../../services/constants';
import ToggleCard from '../../../components/form/ToggleCard';
import { useAxios } from '../../../hooks/useAxios';
import { SimpleTimelineItem } from '../../../components/SimpleTimeline';
import { faCircle, faFlag, faFlagCheckered } from '@fortawesome/free-solid-svg-icons';
import { LoadingView } from '../../../components/LoadingView';

export const GAEventWidget = () => {
  const { profile } = useContext(AppContext);
  const { id } = useParams();
  const [eventData, setEventData] = useState(null);

  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();

  if (profile.type !== USER_TYPE_ADMIN) {
    return null;
  }
  const loadData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`order/${id}/google-analytics/events/`);
      setEventData(response.data);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    return abortRequest;
  }, []);

  return (
    <Card className="mb-3 animate__animated animate__fadeIn animate__delay-4">
      <ToggleCard title={'Google Analytics Events'} endElement={<></>} onOpen={() => loadData()}>
        <div className="bg-light">
          {isLoading || eventData === null ? (
            <div className="p-3">
              <LoadingView />
            </div>
          ) : (
            <>
              {eventData?.alerts.map((alert, index) => (
                <div key={index}>
                  <div className={`m-3 alert ${alert.level} mb-3`}>{alert?.message}</div>
                </div>
              ))}

              {eventData && eventData?.results?.length === 0 ? (
                <div className="text-muted text-center p-3 mb-3">No events found.</div>
              ) : null}

              {eventData?.results?.map((event, index) => {
                let flagName = faFlag;
                if (event.eventName === 'purchase') {
                  flagName = faFlagCheckered;
                } else if (event.eventName === 'add_to_cart') {
                  flagName = faCircle;
                }
                return (
                  <SimpleTimelineItem
                    centerBullet={true}
                    key={index}
                    bullet={
                      <div
                        className={
                          'd-flex justify-content-center align-items-center rounded rounded-circle bg-white border ' +
                          'border-gray-300'
                        }
                        style={{ width: 40, height: 40 }}
                      >
                        <FontAwesomeIcon icon={flagName} />
                      </div>
                    }
                  >
                    <div className="card mb-3 me-3">
                      <div className="card-body">
                        <div className="mb-1">
                          <div>
                            {event.eventName}{' '}
                            {event.eventCount !== '1' ? (
                              <small className="text-muted">&times;{event.eventCount}</small>
                            ) : null}
                          </div>
                          <div className="text-muted">
                            <small>{formatDatetime(event.dateHourMinute)}</small>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="flex-fill d-flex justify-content-end">
                            {event.fullPageUrl ? (
                              <a
                                href={`https://${event.fullPageUrl}`}
                                target="_blank"
                                rel="noreferrer"
                                title="Open full page URL"
                              >
                                <FontAwesomeIcon icon="fas fa-external-link-alt" />
                              </a>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SimpleTimelineItem>
                );
              })}
            </>
          )}
        </div>
      </ToggleCard>
    </Card>
  );
};

GAEventWidget.propTypes = {};
