import { Button, Form, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import { useAxios } from '../hooks/useAxios';
import TextField from './form/TextField';
import { toast } from 'react-toastify';

export const BlackListCustomerModal = ({ params, show, onHide, onSuccess }) => {
  const { axios } = useAxios();
  return (
    <Modal show={show} onHide={onHide} keyboard={false}>
      <Formik
        enableReinitialize={true}
        validateOnMount={true}
        initialValues={{ note: '' }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            const response = await axios.post(`customer/blacklist/${params.customer}/`, { ...values, ...params });
            onSuccess(response?.data);
            toast.success(response?.data?.message, { theme: 'colored' });
          } catch ({ response }) {
            setErrors(response.data);
            toast.warn(response?.data?.message, { theme: 'colored' });
          }
          setSubmitting(false);
        }}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <Form>
            <Modal.Body>
              <TextField name="blacklist_note" type="textarea" label="Reason" disabled={isSubmitting} />
              <div>
                <small className="text-warning">
                  Please explain the reason for considering blacklisting this customer.
                </small>
              </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button variant="secondary" onClick={onHide} disabled={isSubmitting}>
                Close
              </Button>
              <Button
                className="ms-3"
                type="button"
                color="primary"
                disabled={isSubmitting || !values?.blacklist_note}
                onClick={() => handleSubmit()}
              >
                Block
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

BlackListCustomerModal.propTypes = {
  show: PropTypes.any,
  onHide: PropTypes.any,
  onSuccess: PropTypes.any,
  params: PropTypes.object
};
