import { Card, Col, Row } from 'react-bootstrap';
import ToggleCard from '../../../components/form/ToggleCard';
import { getUTMLabel } from '../../../utils/utm';
import React from 'react';
import PropTypes from 'prop-types';

export const MetadataWidget = ({ order }) => {
  if (!order || Object.keys(order?.source_meta_data).length <= 0) {
    return null;
  }
  return (
    <Card className="mb-3 animate__animated animate__fadeIn animate__delay-5">
      <ToggleCard title="Metadata">
        <Card.Body className="bg-light">
          {Object.keys(order?.source_meta_data).map((key, index) => {
            return (
              <Row className="mb-3 g-3" key={index}>
                <Col className="col">
                  <p className="h6">{key.startsWith('utm_') ? getUTMLabel(key) : key}</p>
                </Col>
                <Col className="col-auto">
                  <p className="h6 text-end">{order?.source_meta_data[key]}</p>
                </Col>
              </Row>
            );
          })}
        </Card.Body>
      </ToggleCard>
    </Card>
  );
};

MetadataWidget.propTypes = {
  order: PropTypes.any
};
