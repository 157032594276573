/*eslint camelcase: ["error", {allow: ["gift_card",]}]*/

import React, { useEffect, useState } from 'react';
import Flex from 'components/common/Flex';
import shape1 from 'assets/img/illustrations/shape-1.png';
import Divider from 'components/common/Divider';
import { Form, Formik } from 'formik';
import { Col, Form as FormBootstrap, Row } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import TextField from '../../components/form/TextField';
import LoadingButton from '../../components/common/LoadingButton';
import { useAxios } from '../../hooks/useAxios';
import { useStaticTitle } from '../../hooks/usePageTitle';
import { SUPPORT_WEBSITE } from '../../services/utils';
import { getRedirectTo } from '../../helpers/utils';

const Login = () => {
  useStaticTitle('Login');
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_KEY_PUB;
  const validate = Yup.object({
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.string().required('Password is required')
  });
  const [recaptchaReset, setRecaptchaReset] = useState('');
  const [recaptcha, setRecaptcha] = useState('');
  const [isRecaptchaRequired, setIsRecaptchaRequired] = useState(false);

  const navigate = useNavigate();
  const { axios, abortRequest } = useAxios();

  useEffect(() => abortRequest, []);

  return (
    <>
      <div className="container">
        <img className="bg-auth-circle-shape-2" src={shape1} alt="" width="100" />
        <Flex justifyContent="center" alignItems="center" className="mb-2 w-100">
          <h5>Log in</h5>
        </Flex>

        <Formik
          validateOnMount={true}
          initialValues={{
            email: '',
            password: ''
          }}
          validationSchema={validate}
          onSubmit={async ({ email, password }) => {
            try {
              await axios.post('account/auth/login/', {
                email,
                password,
                ...(recaptcha ? { recaptcha } : {})
              });
              const { data } = await axios.get(`profile/`);
              const redirectUrl = getRedirectTo(data?.permissions);
              navigate(redirectUrl);
            } catch ({ response: { data } }) {
              const recaptchaResult = Array.isArray(data?.recaptcha);
              const passwordResult = Array.isArray(data?.password);
              if (recaptchaResult) {
                toast.error(data?.recaptcha[0], {
                  theme: 'colored'
                });
              } else if (passwordResult) {
                toast.error(data?.password[0], {
                  theme: 'colored'
                });
              } else {
                toast.error(data?.message, {
                  theme: 'colored'
                });
              }
              setIsRecaptchaRequired(!!data?.recaptcha);
              setRecaptchaReset(new Date().toTimeString());
            }
          }}
        >
          {({ isSubmitting, isValid }) => {
            return (
              <div>
                <Form>
                  <TextField label="Email" name="email" type="email" disabled={isSubmitting} autoComplete={'on'} />
                  <TextField
                    label="password"
                    name="password"
                    type="password"
                    disabled={isSubmitting}
                    autoComplete={'on'}
                    minLength={6}
                  />
                  <Row className="justify-content-between align-items-center">
                    <Col xs="auto" />
                    <Col xs="auto">
                      <Link className="fs--1 mb-0" to={`/forgot-password`}>
                        Forgot Password?
                      </Link>
                    </Col>
                  </Row>
                  {isRecaptchaRequired && (
                    <Col md="12 mt-3">
                      <div className="d-flex justify-content-center">
                        <FormBootstrap.Group>
                          <ReCAPTCHA
                            key={recaptchaReset}
                            sitekey={recaptchaSiteKey}
                            theme="light"
                            onChange={(value) => {
                              if (value) {
                                setRecaptcha(value);
                              }
                            }}
                            size="normal"
                          />
                        </FormBootstrap.Group>
                      </div>
                    </Col>
                  )}
                  <LoadingButton disabled={!isValid} className="mt-3 w-100" isLoading={isSubmitting} type="submit">
                    Log In
                  </LoadingButton>
                </Form>
                <Divider className="mt-4">
                  Don't have an account?{' '}
                  <a className="fs--1 mb-0 text-capitalize" href={SUPPORT_WEBSITE} target="_blank" rel="noreferrer">
                    contact us
                  </a>
                </Divider>
              </div>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default Login;
