import React, { useContext, useState } from 'react';
import { Button, Card, Col, Dropdown, Row } from 'react-bootstrap';
import * as _ from 'lodash';
import AdvanceTable from './AdvanceTable';
import AdvanceTablePagination from './AdvanceTablePagination';
import AdvanceTableWrapper from './AdvanceTableWrapper';
import PropTypes from 'prop-types';
import { useVisibleTableFields } from '../../../hooks/useVisibleTableFields';
import TableBulkActions from '../TableBulkActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import { FilterModal } from './FilterModal';
import { SearchInput } from './SearchInput';
import classNames from 'classnames';
import { ColumnDropdown } from './ColumnDropdown';
import { PageLoadingView } from '../../PageLoadingView';
import { toast } from 'react-toastify';
import { useAxios } from '../../../hooks/useAxios';
import AppContext from '../../../context/Context';
import { EXPORT_MAX_EMPTY_COUNT, USER_PERMISSION_EXPORT, USER_TYPE_BRAND } from '../../../services/constants';
import { Swal } from '../../../services/sweetalert';

export const GenericListTable = ({
  isLoading,
  columnsList,
  addRoute,
  data,
  title = null,
  name,
  actions,
  extraComponentButtons,
  filterComponent,
  onSearch,
  search,
  onFilter,
  filters,
  onPaginate,
  rowOptions,
  clearAllFilters,
  onSort,
  emptyLabel,
  tabComponent = null,
  sortBy = [],
  shouldRemoveMargin = false,
  module = null
}) => {
  const navigate = useNavigate();
  const rows = data?.results;
  const pageCount = data?.num_pages;
  const perPage = data?.page_size;
  const resultCount = data?.count;
  const currentPage = data?.page;

  const [filterModalShow, setFilterModalShow] = useState(false);
  const selectableFields = columnsList.map((field) => ({
    name: field.accessor,
    label: field.Header
  }));
  const { visibleFields, setVisibleField } = useVisibleTableFields(name, selectableFields);
  const hiddenColumns = Object.keys(visibleFields).filter((field) => !visibleFields[field]);
  const { axios } = useAxios();
  const { profile } = useContext(AppContext);
  const isBrand = profile?.type === USER_TYPE_BRAND;
  const requestExport = async (payload) => {
    const { data } = await axios.post(`export/`, payload);
    if (data?.success) {
      window.open(`/export/`, '_blank');
    }
  };
  const handleExportClick = async () => {
    try {
      const payload = {
        filters: JSON.stringify(filters),
        type: module
      };
      if (data?.count > EXPORT_MAX_EMPTY_COUNT) {
        const { isConfirmed } = await Swal.fire({
          title: 'Are you sure?',
          text: `No filters have been selected! You are about to export all data`,
          showCancelButton: true,
          confirmButtonColor: '#4E7AE1',
          cancelButtonColor: '#bababa',
          confirmButtonText: 'Confirm',
          showConfirmButton: true,
          cancelButtonText: 'Cancel'
        });
        if (isConfirmed) {
          await requestExport(payload);
        }
      } else {
        await requestExport(payload);
      }
    } catch (error) {
      const errors = error.response?.data;
      if (errors) {
        if (_.isArray(errors?.message) && errors?.message?.length > 0) {
          toast.error(errors?.message[0]);
        } else {
          toast.error(errors?.message);
        }
      }
    }
  };
  const DefaultHeader = ({ selectedRowIds }) => {
    if (selectedRowIds && Object.keys(selectedRowIds).length > 0) {
      return null;
    }
    return (
      <>
        <div className="d-flex align-items-center">
          {extraComponentButtons}

          {addRoute && (
            <Button
              variant="primary"
              size="sm"
              className="text-nowrap"
              onClick={() => navigate(addRoute)}
              id="btn-add-new"
            >
              <FontAwesomeIcon icon="plus" transform="shrink-3" />
              <span className="d-none d-sm-inline-block ms-1 text-nowrap">Add</span>
            </Button>
          )}
        </div>
      </>
    );
  };
  DefaultHeader.propTypes = {
    selectedRowIds: PropTypes.any
  };

  const columns = [...columnsList];
  const eachRowOptions = rowOptions?.filter((option) => option.hasPermission());
  if (eachRowOptions?.length > 0) {
    columns.push({
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: (rowData) => {
        const obj = rowData.row.original;

        return (
          <>
            {eachRowOptions && (
              <Dropdown className="font-sans-serif btn-reveal-trigger" align={'end'}>
                <Dropdown.Toggle variant="link" data-boundary="viewport" className={classNames('text-600', {})}>
                  <FontAwesomeIcon transform="shrink-3" icon="ellipsis-v" className="fs--2" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="border">
                  {eachRowOptions?.map((option, index) => {
                    const item = (
                      <>
                        {option?.icon instanceof Function ? (
                          <FontAwesomeIcon icon={option?.icon(obj)} transform="shrink-3" />
                        ) : (
                          <FontAwesomeIcon icon={option.icon} transform="shrink-3" />
                        )}{' '}
                        {option?.label instanceof Function ? option?.label(obj) : option?.label}
                      </>
                    );
                    if (option.url) {
                      return (
                        <Dropdown.Item key={`row-option-${index}`} to={option.url} as={Link} className="py-2">
                          {item}
                        </Dropdown.Item>
                      );
                    } else {
                      return (
                        <Dropdown.Item
                          key={`row-option-${index}`}
                          onClick={() => {
                            option.onClick(obj);
                          }}
                        >
                          {item}
                        </Dropdown.Item>
                      );
                    }
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </>
        );
      }
    });
  }

  const hasFilter = Object.keys(filters || {}).filter((field) => !['page', 'q', 'ordering'].includes(field)).length > 0;

  return (
    <>
      <div className="animate animate__fadeIn">
        {filterComponent ? (
          <FilterModal
            filters={filters}
            onFilter={(filters) => onFilter(filters)}
            show={filterModalShow}
            handleClose={() => setFilterModalShow(false)}
            filterComponent={filterComponent}
          />
        ) : null}
        <AdvanceTableWrapper
          onSort={onSort}
          initialSortBy={sortBy}
          columns={columns}
          data={rows || []}
          isLoading={isLoading}
          selection={actions?.length > 0}
          sortable
          pagination
          pageCount={pageCount}
          pageSize={perPage}
          hiddenColumns={hiddenColumns}
        >
          <div className="flex-between-center d-flex flex-wrap">
            <div className="d-flex align-items-center pe-0">
              {title && (
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0 d-flex align-items-center">
                  {title} &nbsp;
                  {resultCount && <small className="fs--2"> ( {resultCount} )</small>}
                </h5>
              )}
            </div>
            <div className="d-flex">
              <DefaultHeader table />
              <TableBulkActions table actions={actions} />
            </div>
          </div>
          {['discount', 'gift-card'].includes(module) && profile?.gocardless_url && isBrand && (
            <Card className={classNames({ 'mb-3 mt-3': !shouldRemoveMargin })}>
              <Card.Body>
                <Row className="g-0 align-items-center">
                  <Col className="col-auto d-flex justify-content-center pe-2">
                    <FontAwesomeIcon size={'1x'} className="text-warning" icon="exclamation-circle" />
                  </Col>
                  <Col className="ps-0">
                    {module === 'discount' ? (
                      <h6 className="mb-0">
                        Your discount abilities are currently locked because Bottle Nexus has not registered your
                        account information via Go Cardless. To unlock discounts, please click
                        <a target="_blank" href={profile?.gocardless_url} rel="noreferrer">
                          {' '}
                          here{' '}
                        </a>
                        to add your billing details. <br />
                        Discount costs are billed back with a monthly cadence, with full reporting being shared for your
                        approval prior to each invoice.
                      </h6>
                    ) : (
                      <h6 className="mb-0">
                        Your gift card abilities are currently locked because Bottle Nexus has not registered your
                        account information via Go Cardless. To unlock gift cards, please click
                        <a target="_blank" href={profile?.gocardless_url} rel="noreferrer">
                          {' '}
                          here{' '}
                        </a>
                        to add your billing details. <br />
                        Gift card costs are billed back with a monthly cadence, with full reporting being shared for
                        your approval prior to each invoice.
                      </h6>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}

          <Card className={classNames({ 'mb-3 mt-3': !shouldRemoveMargin })}>
            <div className="card-header p-0">
              {tabComponent ? <div className="px-3 pt-3">{tabComponent}</div> : null}
              <div className="p-3">
                <div className="row">
                  <div className="col-12 col-md-4">
                    {onSearch && (
                      <div>
                        <SearchInput handleSearch={(value) => onSearch(value)} term={search} classNames="w-100" />
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-8 mt-md-0 mt-3">
                    <div className="d-flex justify-content-between justify-content-sm-end flex-wrap">
                      {module && profile.permissions.includes(USER_PERMISSION_EXPORT) && (
                        <Button
                          variant="outline-secondary"
                          size="sm"
                          transform="shrink-3"
                          className="btn-secondary-flat me-1"
                          onClick={() => handleExportClick()}
                        >
                          <FontAwesomeIcon icon="file-export" transform="shrink-3" />
                          <span className="d-none d-sm-inline-block ms-1">Export</span>
                        </Button>
                      )}
                      {filterComponent ? (
                        <div className="d-flex">
                          <Button
                            variant="outline-secondary"
                            size="sm"
                            transform="shrink-3"
                            className="btn-secondary-flat"
                            onClick={() => setFilterModalShow(true)}
                          >
                            <FontAwesomeIcon icon="filter" transform="shrink-3" />
                            <span className="d-none d-sm-inline-block ms-1">Filter</span>
                          </Button>
                          {hasFilter ? (
                            <Button
                              variant="outline-secondary"
                              size="sm"
                              transform="shrink-3"
                              className={classNames('ms-1 btn-secondary-flat', {
                                'd-none': filters === null
                              })}
                              onClick={() => clearAllFilters()}
                            >
                              <FontAwesomeIcon icon="times-circle" transform="shrink-3" />
                              <span className="d-none d-sm-inline-block ms-1">Clear all filters</span>
                            </Button>
                          ) : null}
                        </div>
                      ) : (
                        <></>
                      )}
                      <ColumnDropdown
                        table
                        fields={columnsList.map((field) => ({
                          name: field.accessor,
                          label: field.Header
                        }))}
                        visibleFields={visibleFields}
                        setVisibleField={setVisibleField}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={classNames('p-0 card-body position-relative table-prepare-is-loading', {
                'table-is-loading': isLoading
              })}
            >
              <AdvanceTable
                table
                emptyLabel={emptyLabel}
                onSort={onSort}
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
                shouldRemoveMargin={shouldRemoveMargin}
              />
              <PageLoadingView visible={isLoading} position={'top'} />
            </div>
            {pageCount > 0 ? (
              <Card.Footer>
                <AdvanceTablePagination table onPaginate={onPaginate} currentPage={currentPage} filters={filters} />
              </Card.Footer>
            ) : null}
          </Card>
        </AdvanceTableWrapper>
      </div>
    </>
  );
};

GenericListTable.propTypes = {
  isLoading: PropTypes.bool,
  tabComponent: PropTypes.any,
  emptyLabel: PropTypes.any,
  columnsList: PropTypes.any,
  data: PropTypes.any,
  title: PropTypes.any,
  resultCount: PropTypes.any,
  name: PropTypes.string,
  actions: PropTypes.array,
  addRoute: PropTypes.string,
  extraComponentButtons: PropTypes.any,
  filterComponent: PropTypes.any,
  onSearch: PropTypes.func,
  search: PropTypes.string,
  onFilter: PropTypes.func,
  filters: PropTypes.any,
  rowOptions: PropTypes.array,
  pageCount: PropTypes.number,
  perPage: PropTypes.number,
  onPaginate: PropTypes.func,
  onSort: PropTypes.func,
  sortBy: PropTypes.any,
  clearAllFilters: PropTypes.func,
  shouldRemoveMargin: PropTypes.bool,
  module: PropTypes.string
};
