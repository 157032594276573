/*eslint camelcase: ["error", {allow: ["safe_code","minimum_requirements","type_discount","discount_value", "is_active", "ids_list", "action_type","type_name","eligibility_name","minimum_requirements_value"]}]*/
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GenericListTable } from '../../components/common/ListTable/GenericListTable';
import { cellAsDatetime } from '../../services/utils';
import { Swal } from '../../services/sweetalert';

import {
  CUSTOMER_ELIGIBILITY,
  DISCOUNT_FIXED_AMOUNT,
  DISCOUNT_MINIMUM_REQUIREMENT_PURCHASE,
  DISCOUNT_PERCENTAGE,
  DISCOUNT_TYPE_FREE_SHIPPING,
  ADMIN_OR_BRAND,
  USER_PERMISSION_GIFT_CARD_EDIT
} from '../../services/constants';
import CurrencyFormat from 'react-currency-format';
import { BooleanIndicator } from '../../components/table/BooleanIndicator';
import { useFilters } from '../../hooks/useFilters';
import AsyncSelectField, { SimpleSelectField } from '../../components/form/SelectField';
import YesNoEmptyField from '../../components/form/YesNoEmptyField';
import { FilterRow } from '../../components/FilterRow';
import { useAxios } from '../../hooks/useAxios';
import { useStaticTitle } from '../../hooks/usePageTitle';
import AppContext from '../../context/Context';

const GiftCardList = () => {
  useStaticTitle('Gift Cards');
  const navigate = useNavigate();

  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();

  const [responseData, setResponseData] = useState(null);

  const { search, onSearch, sortBy, onSortBy, filters, onFilter, onPaginate, clearFilter } = useFilters();
  const { profile } = useContext(AppContext);

  const fields = [
    {
      accessor: 'code',
      Header: 'Code',
      headerProps: { className: 'pe-1' },
      cellProps: { className: 'auto-break-row' },
      Cell: (rowData) => {
        const { safe_code, id, expired } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal d-flex align-items-center" to={`/gift-card/${id}/`}>
            <span style={{ fontFamily: 'monospace' }}>{safe_code}</span>
            {expired ? <div className="badge bg-danger ms-2">Expired</div> : ''}
          </Link>
        );
      }
    },
    {
      accessor: 'is_active',
      Header: 'Active',
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/gift-card/${id}/`}>
            <BooleanIndicator isActive={rowData.row.original?.is_active} />
          </Link>
        );
      }
    },
    {
      accessor: 'brand',
      Header: 'Brand',
      Cell: (rowData) => {
        const { id, brand } = rowData.row.original;
        return (
          <>
            <Link className="text-dark fw-normal" to={`/gift-card/${id}/`}>
              {brand?.label}
            </Link>
          </>
        );
      }
    },
    {
      accessor: 'minimum_requirements',
      Header: 'Minimum Requirements',
      Cell: (rowData) => {
        const {
          row: {
            original: { minimum_requirements_name, id }
          }
        } = rowData;
        return (
          <Link className="text-dark fw-normal" to={`/gift-card/${id}/`}>
            {minimum_requirements_name}
          </Link>
        );
      }
    },
    {
      accessor: 'minimum_requirements_value',
      Header: 'Minimum Requirements Value',
      Cell: (rowData) => {
        const { id, minimum_requirements, minimum_requirements_value } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/gift-card/${id}/`}>
            {!minimum_requirements_value ? (
              '-'
            ) : minimum_requirements === DISCOUNT_MINIMUM_REQUIREMENT_PURCHASE ? (
              <CurrencyFormat
                decimalScale={2}
                fixedDecimalScale={true}
                value={minimum_requirements_value}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            ) : (
              minimum_requirements_value
            )}
          </Link>
        );
      }
    },
    {
      accessor: 'eligibility',
      Header: 'Eligibility',
      Cell: (rowData) => {
        const { id, eligibility_name } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/gift-card/${id}/`}>
            {eligibility_name}
          </Link>
        );
      }
    },
    {
      accessor: 'value',
      Header: 'Value',
      Cell: (rowData) => {
        const { id, value, type } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/gift-card/${id}/`}>
            {DISCOUNT_PERCENTAGE.includes(type) && value + ' %'}
            {DISCOUNT_FIXED_AMOUNT.includes(type) && (
              <CurrencyFormat
                decimalScale={2}
                fixedDecimalScale={true}
                value={value}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            )}
            {type === DISCOUNT_TYPE_FREE_SHIPPING && '-'}
          </Link>
        );
      }
    },
    {
      accessor: 'starts_at',
      Header: 'Starts At',
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/gift-card/${id}/`}>
            {cellAsDatetime(rowData, 'starts_at')}
          </Link>
        );
      }
    },
    {
      accessor: 'ends_at',
      Header: 'Ends At',
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/gift-card/${id}/`}>
            {cellAsDatetime(rowData, 'ends_at')}
          </Link>
        );
      }
    },
    {
      accessor: 'expired',
      disableSortBy: true,
      Header: 'Expired',
      Cell: (rowData) => {
        const { id, expired } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/gift-card/${id}/`}>
            {expired ? <span className="text-danger">Expired</span> : '-'}
          </Link>
        );
      }
    }
  ];
  const filterComponent = (
    <>
      {ADMIN_OR_BRAND.includes(profile?.type) && (
        <FilterRow name="portfolios">
          <AsyncSelectField
            isMulti={true}
            label="Portfolios"
            name="portfolios"
            urlEndpoint={'/dropdown/portfolio/'}
            className="mb-0"
          />
        </FilterRow>
      )}
      <FilterRow name="brand">
        <AsyncSelectField label="Brand" name="brand" urlEndpoint={'/dropdown/brand/'} className="mb-0" />
      </FilterRow>
      <FilterRow name="eligibility">
        <SimpleSelectField
          label="Customer Eligibility"
          name="eligibility"
          options={CUSTOMER_ELIGIBILITY}
          className="mb-0"
        />
      </FilterRow>

      <YesNoEmptyField label="Active" name="is_active" />
      <YesNoEmptyField label="Expired" name="expired" noLabel="No" yesLabel="Yes" className="mb-0 mt-3" />
    </>
  );
  const rowOptions = [
    {
      label: 'Edit',
      icon: 'pencil-alt',
      onClick: (obj) => navigate(`/gift-card/${obj?.id}/`),
      hasPermission: () => {
        return true;
      }
    },
    {
      name: 'disable',
      icon: (obj) => (obj?.is_active ? 'times' : 'check'),
      label: (obj) => (obj?.is_active ? 'Disable' : 'Enable'),
      onClick: (obj) => disableDiscount([obj?.id], obj?.is_active ? 'disable' : 'enable'),
      hasPermission: () => {
        return true;
      }
    }
  ];
  const loadGiftCard = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`gift-card/`, {
        params: filters
      });
      setResponseData(response?.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    loadGiftCard().catch(console.error);
    return abortRequest;
  }, [filters]);

  const disableDiscount = async (ids, action_type) => {
    const textMsg = ids.length > 1 ? `${ids.length} gift cards` : `${ids.length} gift card`;
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      text: `You are about to update ${textMsg}`,
      showCancelButton: true,
      showConfirmButton: true
    });
    if (isConfirmed) {
      try {
        const response = await axios.post('gift-card/disable/', {
          ids_list: ids,
          action_type: action_type
        });
        await loadGiftCard();
        toast.success(response?.data?.message, { theme: 'colored' });
      } catch (e) {
        toast.error(e?.response?.data?.message, { theme: 'colored' });
      }
    }
  };
  const actions = [
    {
      name: 'enable',
      label: 'Enable',
      forceResetSelection: true,
      fn: disableDiscount,
      hasPermission: () => {
        return true;
      }
    },
    {
      name: 'disable',
      label: 'Disable',
      forceResetSelection: true,
      fn: disableDiscount,
      hasPermission: () => {
        return true;
      }
    }
  ].filter((action) => action.hasPermission());
  return (
    <>
      <GenericListTable
        sortBy={sortBy}
        onSort={onSortBy}
        onPaginate={onPaginate}
        title={'Gift Cards'}
        name="gift card"
        addRoute={profile?.permissions?.includes(USER_PERMISSION_GIFT_CARD_EDIT) ? '/gift-card/create/' : null}
        filterComponent={filterComponent}
        actions={profile?.permissions?.includes(USER_PERMISSION_GIFT_CARD_EDIT) ? actions : null}
        search={search}
        onSearch={onSearch}
        filters={filters}
        onFilter={onFilter}
        clearAllFilters={clearFilter}
        rowOptions={rowOptions}
        columnsList={fields}
        isLoading={!responseData || isLoading}
        data={responseData}
        module="gift-card"
      />
    </>
  );
};

export default GiftCardList;
