import React, { Suspense, useContext, useEffect } from 'react';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { LoadingView } from './LoadingView';
import { useAxios } from '../hooks/useAxios';
import LoadingScreen from '../pages/LoadingScreen';
import AppContext from '../context/Context';
import { setParameter } from '../utils/ga';

const AuthenticatedLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { profile, setProfile } = useContext(AppContext);
  const { axios, abortRequest } = useAxios();

  const getModuleFromPath = (pathname) => {
    if (pathname.startsWith('/discount')) {
      return 'discount';
    }
    if (pathname.startsWith('/gift-card')) {
      return 'gift-card';
    }
    return null;
  };
  const loadUserInfo = async (module = null) => {
    try {
      const config = module ? { params: { module } } : {};
      const { data } = await axios.get(`profile/`, config);
      setProfile(data);
      setParameter(data?.id, data?.type, data?.email);
    } catch (e) {
      const { response } = e;
      if (response?.status === 401) {
        navigate('/login');
      }
    }
  };

  useEffect(() => {
    const module = getModuleFromPath(location.pathname);
    loadUserInfo(module).catch(console.error);
    return abortRequest;
  }, [location.pathname]);

  return (
    <>
      <Suspense fallback={<LoadingScreen />}>{profile ? <Outlet /> : <LoadingView />}</Suspense>
    </>
  );
};

export default AuthenticatedLayout;
