import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import 'assets/scss/custom.scss';
import 'animate.css';
import ReactGA from 'react-ga4';
import { ENVIRONMENT_TYPE } from './utils';

if (ENVIRONMENT_TYPE === 'production') {
  ReactGA.initialize('G-89LT8P6HLE');
}

ReactDOM.render(
  <React.StrictMode>
    <Main>
      <App />
    </Main>
  </React.StrictMode>,

  document.getElementById('main')
);
